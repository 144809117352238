<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Multiselect from "@vueform/multiselect";

export default {
  page: {
    title: "Rekapitulasi Kuisioner",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    Pagination,
  },
  data() {
    return {
      title: "Rekapitulasi Kuisioner",

      filter: {
        tanggal_dari: moment().startOf("month").format("YYYY-MM-DD"),
        tanggal_sampai: moment().format("YYYY-MM-DD"),
        id_jadwal: "",
        id_jenis_asesmen: "",
        id_materi: "",
        id_simulasi: "",
      },

      selected_jenis_asesmen: "",
      selected_materi: "",
      selected_simulasi: "",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      currentTablePage: "",
      selected_simulasi_file: "",
      option_jenis_asesmen: [],
      option_materi: [],
      option_simulasi_config: [],
      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        filter_status: "ENABLE", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.getDataTable();
    self.getJenisAsesmen();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getJenisAsesmen(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_jenis_asesmen = response.data.data.referensi;
          console.log(self.option_jenis_asesmen);
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    getMateri(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_jenis_asesmen: this.filter.id_jenis_asesmen,
          keyword: keyword,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_materi = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    getSimulasiConfig(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/simulasi-by-materi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
          id_materi: this.filter.id_materi,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_simulasi_config = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "rekapitulasi-kuisioner"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: this.filter,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.data.list;
            console.log(self.table_data);
            self.configPagination(response_data_fix.data.pagination);
            self.currentTablePage =
              response_data_fix.data.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },

    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },

    onSelectJenisAsesmen(selectedOption) {
      this.filter.id_jenis_asesmen = selectedOption.id;
      this.getMateri();
    },
    onSelectMateri(selectedOption) {
      this.filter.id_materi = selectedOption.mm_id;
      this.getSimulasiConfig();
    },
    onSelectSimulasi(selectedOption) {
      this.filter.id_simulasi = selectedOption.id_simulasi;
      this.getSimulasiConfig();
    },
    formExportLaporan() {
      var config = {
        method: "get",
        responseType: "arraybuffer",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-rekapitulasi-kuisioner",
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
          Accept: "application/vnd.ms-excel",
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/csv" })
        );
        // console.log(url);
        const timestamp = moment().format("YYYYMMDD_HHmmss");
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "rekapitulasi-kuisioner-" + timestamp + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  searchdata(data) {
    this.getJenisAsesmen(data);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Rekapitulasi Kuisioner</div>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-end">
                  <div class="d-flex ps-2">
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      v-on:click="formExportLaporan()"
                    >
                      <i class="mdi mdi-file-document"></i>
                      EXPORT
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2 col-sm-12">
                    <label for="statusTable">Tanggal Dari</label>
                    <b-form-input v-model="filter.tanggal_dari" type="date" />
                  </div>
                  <div class="form-group col-md-2 col-sm-12">
                    <label for="statusTable">Tanggal Sampai</label>
                    <b-form-input v-model="filter.tanggal_sampai" type="date" />
                  </div>
                  <div class="form-group col-md-1 col-sm-12">
                    <label for="statusTable">ID Jadwal</label>
                    <b-form-input v-model="filter.id_jadwal" type="number" />
                  </div>
                  <div class="form-group col-md-2 col-sm-12">
                    <label for="statusTable">Dirkom</label>
                    <multiselect
                      v-model="selected_jenis_asesmen"
                      :options="option_jenis_asesmen"
                      :object="true"
                      value-prop="id"
                      :close-on-select="true"
                      track-by="nama_jenisasesmen"
                      :searchable="true"
                      label="nama_jenisasesmen"
                      placeholder="Pilih Jenis Asesmen"
                      @select="onSelectJenisAsesmen($event)"
                    ></multiselect>
                  </div>
                  <div class="form-group col-md-2 col-sm-12">
                    <label for="statusTable">Materi</label>
                    <multiselect
                      v-model="selected_materi"
                      :options="option_materi"
                      :object="true"
                      value-prop="mm_id"
                      :close-on-select="true"
                      track-by="mm_nama"
                      :searchable="true"
                      label="mm_nama"
                      placeholder="Pilih Materi"
                      @select="onSelectMateri($event)"
                    ></multiselect>
                  </div>
                  <div class="form-group col-md-2 col-sm-12">
                    <label for="statusTable">Simulasi</label>
                    <multiselect
                      v-model="selected_simulasi"
                      :options="option_simulasi_config"
                      :object="true"
                      value-prop="id_simulasi"
                      :close-on-select="true"
                      track-by="nama_simulasi"
                      :searchable="true"
                      label="nama_simulasi"
                      placeholder="Pilih Simulasi"
                      @select="onSelectSimulasi($event)"
                    ></multiselect>
                  </div>
                  <div class="form-group col-md-1 align-self-end col-sm-12">
                    <button
                      class="btn btn-md btn-success"
                      @click="getDataTable()"
                    >
                      <i class="fa fa-search"></i> Filter
                    </button>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.per_page"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered mb-0">
                  <thead class="table-dark">
                    <tr>
                      <th>No</th>
                      <th>NIP</th>
                      <th>Nama</th>
                      <th>ID Jadwal</th>
                      <th>Nama Simulasi</th>
                      <th>Nama Materi</th>
                      <th>Jenis Asesmen</th>
                      <th v-for="(thead_data, key_data) in 7" :key="key_data">
                        Jawaban {{ key_data + 1 }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="6">
                        Data Tidak Tersedia
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(row_data, key_data) in table_data"
                      :key="key_data"
                    >
                      <td class="text-center">
                        <div v-if="key_data + 1 == 10">
                          {{ currentTablePage }}0.
                        </div>
                        <div v-else>
                          {{
                            currentTablePage - 1 != 0
                              ? currentTablePage - 1
                              : ""
                          }}{{ key_data + 1 }}.
                        </div>
                      </td>
                      <td>
                        {{ row_data.nip_asesi ?? "-" }}
                      </td>
                      <td>
                        {{ row_data.user_by_nip.u_nama ?? "-" }}
                      </td>
                      <td>
                        {{ row_data.id_jadwal ?? "-" }}
                      </td>
                      <td>
                        {{ row_data.simulasi.nama_simulasi ?? "-" }}
                      </td>
                      <td>
                        {{ row_data.simulasi.materi.mm_nama ?? "-" }}
                      </td>
                      <td>
                        {{
                          row_data.simulasi.materi.jenis_asesmen
                            .nama_jenisasesmen ?? "-"
                        }}
                      </td>
                      <td
                        v-for="(
                          thead_data, key_data
                        ) in row_data?.detail_jawaban_kuisioner"
                        :key="key_data"
                      >
                        {{ thead_data.jawaban ?? "-" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
